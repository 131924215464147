import { configureStore } from "@reduxjs/toolkit";
import supplierReducers from "./supplier";
import configuratorReducers from "./configurator";

//todo: have a standard way of dynamically importing all the slices
//e.g import * as reducer => this should be a mapping like the one passed in reducer below

export const store = configureStore({
  reducer: {
    ...supplierReducers,
    ...configuratorReducers,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
