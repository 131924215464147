import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { DcSite } from "components/configurator/oadc/interfaces";

type PopsState = {
  pops: DcSite[];
  popsLoaded: boolean;
};

const getInitialState = () => {
  const initialState: PopsState = {
    pops: [], //require("../../../components/map/dc_pops.json"), //[]
    popsLoaded: false,
  };

  return initialState;
};

const popsSlice = createSlice({
  name: "oadc/pops",
  initialState: getInitialState(),
  reducers: {
    popsLoaded: (state, action: PayloadAction<PopsState["pops"]>) => {
      state.pops = action.payload.map((pop) => ({
        ...pop,
        site_name: pop.site_name || pop.name,
        site_id: pop.site_id || pop.site_code,
        country: pop.site_country || pop.country,
      }));
      state.popsLoaded = true;
    },
  },
});

export const { popsLoaded } = popsSlice.actions;

export default popsSlice.reducer;
