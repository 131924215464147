import ErrorBoundary from "common/error-boundary";
import React from "react";
import { ToastProvider } from "react-toast-notifications";
import GlobalStateProvider from "store/GlobalStateProvider";
import ReduxProvider from "store/ReduxStoreProvider";
import CustomMuiThemeProvider from "theme/mui-theme";
import ReactQueryClientProvider from "./react-query-provider";

interface Props {
  children: React.ReactNode;
}

const AppProviders = ({ children }: Props) => {
  return (
    <ErrorBoundary>
      <GlobalStateProvider>
        <ReduxProvider>
          <ToastProvider autoDismiss={true} autoDismissTimeout={10000}>
            <CustomMuiThemeProvider>
              <ReactQueryClientProvider>{children}</ReactQueryClientProvider>
            </CustomMuiThemeProvider>
          </ToastProvider>
        </ReduxProvider>
      </GlobalStateProvider>
    </ErrorBoundary>
  );
};

export default AppProviders;
