import { SetStateFn } from "components/supplier-v2/onboarding/interfaces";

export type OnFileChangeHandler = React.ChangeEventHandler<HTMLInputElement>;
export type FileObject = File | null;
export type NullableStr = string | null;

export interface Documents {
  names: (NullableStr | NullableStr[])[];
  files: (FileObject | FileObject[])[];
  error: NullableStr;
}

export interface EncodedAttachment {
  name: string;
  file: string;
  record_id?: string;
  document_type_id: string;
  document_type?: string;
  file_id?: string;
  remove?: boolean;
  stagedForDelete?: boolean;
}

export type PreviewDocFn = (
  file?: File | string,
  isPDF?: boolean,
  fileId?: string
) => void;

export type ExistingDocuments = Record<string | number, EncodedAttachment>;
export type AddedDocuments = Record<
  string,
  EncodedAttachment | EncodedAttachment[]
>;

export type ExistingClientDocuments = AddedDocuments;

export type ShowSelectedDocuments = (
  processing: boolean,
  index: number
) => React.ReactNode;

export interface EncodeCallbacks extends Record<string, Function> {
  setProcessing: SetStateFn<boolean>;
  onSaveDocumentsToNetSuite: (documents: EncodedAttachment[]) => void;
}

export type EncodeFunction = (
  docTypeId: string,
  encodedAttachments: EncodedAttachment[],
  docTypeAcceptsMultipleFiles?: boolean,
  callbacks?: Partial<EncodeCallbacks>
  // update?: boolean
) => void;

export type UpdateDeletedRemoteDocFn = (deleted: EncodedAttachment) => void;

export type RemoveRemoteDocFn = (
  docType: string,
  subIndex?: number,
  updateDeleted?: UpdateDeletedRemoteDocFn
) => void;

export enum StageEvent {
  STAGE_FOR_DELETE = "STAGE_FOR_DELETE",
  UN_STAGE_DELETE = "UN_STAGE_DELETE",
}

export type StageRemoveRemoteDocFn = (
  docType: string,
  subIndex: number | undefined,
  stageEvent: StageEvent
) => void;
