import { HttpResponse, ResponsePayload } from "./interfaces";

const GET = "GET";
const POST = "POST";
const PATCH = "PATCH";
const DELETE = "DELETE";

const HTTP = "http://",
  HTTPS = "https://";

// const OK_STATUS = 200;
const UNAUTHORIZED_STATUS = 401;

export type HttpPayload = any | Record<string | number, unknown> | null;

export type ApiResponse<T> = T | HttpResponse<T>;

const makeHttpCall = async <T>(
  endpoint: string,
  method = GET,
  asBlob = false,
  data: HttpPayload = null
) => {
  const requestConfig: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    mode: "cors",
    credentials: "include",
  };

  if (document.cookie) {
    const csrfToken =
      document.cookie
        ?.split("; ")
        .find((row) => row.startsWith("csrf_access_token="))
        ?.split("=")[1] ?? null;

    if (csrfToken) {
      const newHeaders = new Headers(requestConfig.headers);
      newHeaders.set("X-CSRF-TOKEN", csrfToken);
      requestConfig.headers = newHeaders;
    }
  }

  if (method !== GET && data) {
    requestConfig.body = JSON.stringify(data);
  }

  const url =
    endpoint.startsWith(HTTP) || endpoint.startsWith(HTTPS)
      ? endpoint
      : `${process.env.REACT_APP_API_URL}/api/${endpoint}`;

  if (method !== GET && data) requestConfig.body = JSON.stringify(data);
  const _response = await fetch(url, requestConfig);
  if (!_response.ok) {
    // redirect users on signature expiry
    if (
      // _response.status === 422||
      _response.status === 401
    ) {
      if (!window.location.href.includes("/login")) {
        return (window.location.href = `${window.location.origin}/login`);
      }
    }

    throw new Error(getError(_response));
  }

  return await (asBlob ? _response.blob() : (_response.json() as T));
};

const getError = (response: Response) => {
  switch (response.status) {
    case 504:
      return "Connection error.";
    case 422:
    case UNAUTHORIZED_STATUS:
      return "Login session expired. Please login again";
    default:
      break;
  }

  return `An error has occured: ${response.status}`;
};

export async function fetchJsonFromApiJsonResponse<T = any>(endpoint: string) {
  return makeHttpCall<T>(endpoint) as T;
}

export async function apiGet<T = ResponsePayload>(endpoint: string) {
  return fetchJsonFromApiJsonResponse<HttpResponse<T>>(endpoint) as T;
}

export async function fetchJsonFromApiBlobResponse(endpoint: string) {
  return makeHttpCall<Blob>(endpoint, GET, true);
}

export async function postDataToApiJsonResponse<T = any>(
  endpoint: string,
  body: HttpPayload
) {
  return makeHttpCall<T>(endpoint, POST, false, body) as unknown as T;
}
export async function apiDelete<T = ResponsePayload>(
  endpoint: string,
  body?: HttpPayload
) {
  return makeHttpCall<T>(endpoint, DELETE, false, body) as unknown as T;
}
export async function apiPost<T = ResponsePayload>(
  endpoint: string,
  body: HttpPayload
) {
  return postDataToApiJsonResponse<HttpResponse<T>>(endpoint, body);
}

export async function apiPatch<T = ResponsePayload>(
  endpoint: string,
  body: HttpPayload
) {
  return makeHttpCall<T>(endpoint, PATCH, false, body) as unknown as T;
}

export async function postDataToApiBlobResponse(endpoint: string, body: any) {
  return makeHttpCall<Blob>(endpoint, POST, true, body);
}
