import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  EncodedAttachment,
  StageEvent,
} from "components/Hooks/attachments/interfaces";
import {
  KycDocType,
  ExistingDocuments,
} from "components/supplier-v2/onboarding/sections/documents/interfaces";

type SoDocuments = {
  documents: EncodedAttachment[] | undefined;
  deletedDocuments: EncodedAttachment[];
  localDocuments: ExistingDocuments;
  kycDocTypes?: KycDocType[];
  stagedDeletionIndices: Record<string, number[]>;
  isUploading?: boolean;
  uploadDocuments: EncodedAttachment[];
};

const getInitialState = (): SoDocuments => {
  return {
    documents: undefined,
    localDocuments: {},
    deletedDocuments: [],
    stagedDeletionIndices: {},
    uploadDocuments: [],
  };
};

type DeletePayload = { docType: string; subIndex?: number };

type DocStagingPayload = {
  docType: string;
  subIndex: number | undefined;
  stageEvent: StageEvent;
};

type UploadPayload = {
  documents: EncodedAttachment[];
  docType: string;
  docTypeAcceptsMultipleFiles: boolean;
};

const supplierDocumentsSlice = createSlice({
  name: "supplier/onboarding/documents",
  initialState: getInitialState(),
  reducers: {
    kycDocsLoaded: (state, action: PayloadAction<KycDocType[]>) => {
      state.kycDocTypes = action.payload;
    },

    onDocumentsLoaded: (state, action: PayloadAction<EncodedAttachment[]>) => {
      if (!state.documents) state.documents = [];
      state.documents?.push(...action.payload);
    },

    removeRemoteDoc: (state, action: PayloadAction<DeletePayload>) => {
      if (!state.deletedDocuments) state.deletedDocuments = [];
      if (!state.documents) state.documents = [];

      const { docType, subIndex } = action.payload;

      if (!docType) return;

      let docTypeIndex = -1;

      const markedForDelete: EncodedAttachment[] = [
        ...(state.deletedDocuments ?? []),
      ];

      const filteredDocuments = state.documents.filter((doc) => {
        if (String(doc.document_type_id) === String(docType)) {
          docTypeIndex++;

          /*if subindex is not specified, it means we want to delete all files of that type,
          else only delete file at that index */

          if (subIndex !== undefined) {
            if (docTypeIndex !== subIndex) return true;
          }

          markedForDelete.push({ ...doc, remove: true });

          // return subIndex === undefined ? false : docTypeIndex !== subIndex;
          return false;
        }

        return true;
      });

      state.deletedDocuments = markedForDelete;
      state.documents = filteredDocuments;
    },

    stageRemoveRemoteDoc: (state, action: PayloadAction<DocStagingPayload>) => {
      if (!state.documents) state.documents = [];

      const { docType, subIndex, stageEvent } = action.payload;

      let docTypeIndex = -1;

      const mappedDocuments = state.documents.map((doc) => {
        if (String(doc.document_type_id) === String(docType)) {
          docTypeIndex++;
          /*if subindex is not specified, it means we want to delete all files of that type,
          else only delete file at that index */

          if (subIndex !== undefined) {
            if (docTypeIndex !== subIndex) return doc;
          }

          doc.stagedForDelete = stageEvent === StageEvent.STAGE_FOR_DELETE;

          return doc;
        }

        return doc;
      });

      if (docType !== undefined) {
        if (!state.stagedDeletionIndices) state.stagedDeletionIndices = {};

        if (!state.stagedDeletionIndices[docType]) {
          state.stagedDeletionIndices[docType] = [];
        }

        if (subIndex !== undefined) {
          state.stagedDeletionIndices[docType].push(subIndex);
        }
      }

      state.documents = mappedDocuments;
    },

    clearStagedDeletion: (state) => {
      state.stagedDeletionIndices = {};
    },

    unStageDocDeletion: (state) => {
      state.stagedDeletionIndices = {};
    },

    onUploadDocuments: (state, action: PayloadAction<UploadPayload>) => {
      const { documents, docType, docTypeAcceptsMultipleFiles } =
        action.payload;

      const encodedAttachments: ExistingDocuments = {
        ...state.localDocuments,
      };

      encodedAttachments[docType] = documents.map((doc) => {
        doc.document_type_id = String(docType);
        return doc;
      });

      const typeDocuments = documents
        .concat(
          (docTypeAcceptsMultipleFiles ? state.documents ?? [] : []).filter(
            (doc) => doc.document_type_id === docType && !doc.stagedForDelete
          )
        )
        .concat(
          (state.deletedDocuments ?? []).filter(
            (doc) => doc.document_type_id === docType
          )
        );

      state.uploadDocuments = typeDocuments;
    },

    onDocumentsSaved: (
      state,
      action: PayloadAction<EncodedAttachment[] | undefined>
    ) => {
      if (action.payload !== undefined) {
        state.documents = action.payload;
      }

      state.deletedDocuments = [];
      state.stagedDeletionIndices = {};
      state.uploadDocuments = [];
      state.isUploading = false;
    },

    onUploadStarted: (state) => {
      state.isUploading = true;
    },
  },
});

export const {
  onDocumentsLoaded,
  removeRemoteDoc,
  kycDocsLoaded,
  stageRemoveRemoteDoc,
  unStageDocDeletion,
  clearStagedDeletion,
  onUploadDocuments,
  onDocumentsSaved,
  onUploadStarted,
} = supplierDocumentsSlice.actions;

export default supplierDocumentsSlice.reducer;
