import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { OCompanyContact } from "components/supplier-v2/onboarding/sections/contacts/interfaces";

type SoContacts = {
  contacts: OCompanyContact[] | undefined;
  contactIdMap: Record<string, OCompanyContact>;
};

const getInitialState = (): SoContacts => {
  return {
    contacts: undefined,
    contactIdMap: {},
  };
};

const supplierContactsSlice = createSlice({
  name: "supplier/onboarding/contacts",
  initialState: getInitialState(),
  reducers: {
    onContactsLoaded: (state, action: PayloadAction<OCompanyContact[]>) => {
      if (!state.contacts) state.contacts = [];

      action.payload.forEach((contact) => {
        const { contact_id = "" } = contact;
        state.contactIdMap[contact_id] = contact;
        state.contacts?.push(contact);
      });
    },
    onContactUpdated: (
      state,
      action: PayloadAction<[number, OCompanyContact]>
    ) => {
      if (!state.contacts) return;
      const existingContacts = [...state.contacts];
      const [index, contact] = action.payload;
      existingContacts[index] = contact;
      state.contacts = existingContacts;
    },
    onContactsAdded: (state, action: PayloadAction<OCompanyContact>) => {
      if (!state.contacts) state.contacts = [];
      state.contacts.push(action.payload);
    },
  },
});

export const { onContactsAdded, onContactsLoaded, onContactUpdated } =
  supplierContactsSlice.actions;

export default supplierContactsSlice.reducer;
