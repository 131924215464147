import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { OReference } from "components/supplier-v2/onboarding/sections/references/interfaces";

type SoReferences = {
  references: OReference[] | undefined;
  uniqueEmails: Array<string>;
};

const getInitialState = (): SoReferences => {
  return {
    references: undefined,
    uniqueEmails: [],
  };
};

const supplierReferencesSlice = createSlice({
  name: "supplier/onboarding/references",
  initialState: getInitialState(),
  reducers: {
    onReferencesLoaded: (state, action: PayloadAction<OReference[]>) => {
      if (!state.references) state.references = [];

      action.payload.forEach((reference) => {
        const { email_address } = reference;
        if (state.uniqueEmails.includes(email_address.toLowerCase())) return;
        state.uniqueEmails.push(email_address.toLowerCase());
        state.references?.push(reference);
      });
    },
    onReferenceUpdated: (
      state,
      action: PayloadAction<[number, OReference]>
    ) => {
      if (!state.references) return;
      const existingReferences = [...state.references];
      const [index, reference] = action.payload;
      existingReferences[index] = reference;
      state.references = existingReferences;
    },
    onReferenceAdded: (state, action: PayloadAction<OReference>) => {
      if (!state.references) state.references = [];
      state.uniqueEmails.push(action.payload.email_address.toLowerCase());
      state.references.push(action.payload);
    },
    onReferenceDeleted: (state, action: PayloadAction<number>) => {
      if (!state.references) return;
      const existingReferences = [...state.references];
      const index = action.payload;
      existingReferences[index].remove = true;
      state.references = existingReferences;
    },

    onReferencesSaved: (state, action: PayloadAction<OReference[]>) => {
      state.references = action.payload;
    },
  },
});

export const {
  onReferenceAdded,
  onReferencesLoaded,
  onReferenceUpdated,
  onReferenceDeleted,
  onReferencesSaved,
} = supplierReferencesSlice.actions;

export default supplierReferencesSlice.reducer;
