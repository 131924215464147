import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { OAddress } from "components/supplier-v2/onboarding/sections/addresses/interfaces";

type SoAddresses = {
  addresses: OAddress[] | undefined;
};

const getInitialState = (): SoAddresses => {
  return {
    addresses: undefined,
  };
};

const supplierAddressesSlice = createSlice({
  name: "supplier/onboarding/addresses",
  initialState: getInitialState(),
  reducers: {
    onAddressesLoaded: (state, action: PayloadAction<OAddress[]>) => {
      if (!state.addresses) state.addresses = [];

      action.payload.forEach((address) => {
        state.addresses?.push(address);
      });
    },
    onAddressUpdated: (state, action: PayloadAction<[number, OAddress]>) => {
      if (!state.addresses) return;
      const existingAddresses = [...state.addresses];
      const [index, address] = action.payload;
      existingAddresses[index] = address;
      state.addresses = existingAddresses;
    },
    onAddresssAdded: (state, action: PayloadAction<OAddress>) => {
      if (!state.addresses) state.addresses = [];
      state.addresses.push(action.payload);
    },

    onAddresssDeleted: (state, action: PayloadAction<number>) => {
      if (!state.addresses) return;
      const existingAddresses = [...state.addresses];
      const index = action.payload;
      existingAddresses[index].remove = true;
      state.addresses = existingAddresses;
    },
  },
});

export const {
  onAddresssAdded,
  onAddressesLoaded,
  onAddressUpdated,
  onAddresssDeleted,
} = supplierAddressesSlice.actions;

export default supplierAddressesSlice.reducer;
