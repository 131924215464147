import { lazy } from "react";
import { AppRoute } from "./interfaces";

const ConfiguratorDashboard = lazy(() => import("../pages/configurator"));
const NewQuote = lazy(() => import("../pages/configurator/oadc/new-quote"));
const Pricing = lazy(() => import("../pages/configurator/oadc/pricing"));

const CONFIGURATOR_ROUTES: AppRoute[] = [
  {
    path: "/configurator/oadc",
    element: ConfiguratorDashboard,
  },
  {
    path: "/configurator/oadc/quotes/new",
    element: NewQuote,
  },
  {
    path: "/configurator/oadc/pricing",
    element: Pricing,
  },
];

export default CONFIGURATOR_ROUTES;
