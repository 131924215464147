import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  ODeclaration,
  SupplierProfile,
} from "components/supplier-v2/onboarding/interfaces";

type SoP = {
  submitted?: boolean;
  profile: Partial<SupplierProfile> | undefined;
  loadSubRecords: boolean;
  subRecordsLoaded: boolean;
};

const getInitialState = () => {
  const initialState: SoP = {
    profile: undefined,
    subRecordsLoaded: false,
    loadSubRecords: false,
  };

  return initialState;
};

const supplierOnboardingSlice = createSlice({
  name: "supplier/onboarding/profile",
  initialState: getInitialState(),
  reducers: {
    onProfileLoaded: (state, action: PayloadAction<SoP["profile"]>) => {
      state.profile = { ...(state.profile ?? {}), ...action.payload };
    },

    onBankDetailsUpdated: (state, action: PayloadAction<SoP["profile"]>) => {
      state.profile = { ...(state.profile ?? {}), ...action.payload };
    },

    onProfileUpdated: (state, action: PayloadAction<SoP["profile"]>) => {
      if (!action.payload || Object.keys(action.payload).length === 0) {
        return;
      }
      state.profile = { ...(state.profile ?? {}), ...action.payload };
    },

    onPreview: (state, action: PayloadAction<ODeclaration>) => {
      if (state.profile) {
        state.profile.declaration_email = action.payload.declaration_email;
        state.profile.declaration_name = action.payload.declaration_name;
      }

      if (state.subRecordsLoaded) return;
      state.loadSubRecords = true;
      state.subRecordsLoaded = false;
    },

    onSubRecordsLoaded: (state, action: PayloadAction<SoP["profile"]>) => {
      state.profile = { ...(state.profile ?? {}), ...action.payload };
      state.subRecordsLoaded = true;
      state.loadSubRecords = false;
    },

    onSubmit: (state) => {
      state.submitted = true;
    },
  },
});

export const {
  onProfileLoaded,
  onBankDetailsUpdated,
  onProfileUpdated,
  onPreview,
  onSubRecordsLoaded,
} = supplierOnboardingSlice.actions;

export default supplierOnboardingSlice.reducer;
