import onboardingSlice from "./onboarding-slice";
import supplierContactsSlice from "./contacts-slice";
import supplierAddressesSlice from "./address-slice";
import supplierServicesSlice from "./services-slice";
import supplierReferencesSlice from "./references-slice";
import supplierDocumentsSlice from "./documents-slice";

const supplierReducers = {
  supplierOnboarding: onboardingSlice,
  supplierContacts: supplierContactsSlice,
  supplierAddresses: supplierAddressesSlice,
  supplierServices: supplierServicesSlice,
  supplierReferences: supplierReferencesSlice,
  supplierDocuments: supplierDocumentsSlice,
};

export default supplierReducers;
