import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/Form/mui/types";
import { OSupplierService } from "components/supplier-v2/onboarding/sections/supplier-services/interfaces";

type SoServices = {
  services: OSupplierService[] | undefined;
  selectedServices: SelectOption[] | undefined;
};

const getInitialState = (): SoServices => {
  return {
    services: undefined,
    selectedServices: undefined,
  };
};

const supplierServicesSlice = createSlice({
  name: "supplier/onboarding/services",
  initialState: getInitialState(),
  reducers: {
    onServicesLoaded: (state, action: PayloadAction<SelectOption[]>) => {
      if (!state.services) state.selectedServices = [];
      state.selectedServices?.push(...action.payload);
    },

    onServiceUpdated: (
      state,
      action: PayloadAction<[number, OSupplierService]>
    ) => {
      if (!state.services) return;
      const existingServices = [...state.services];
      const [index, service] = action.payload;
      existingServices[index] = service;
      state.services = existingServices;
    },
    onServicesAdded: (state, action: PayloadAction<OSupplierService>) => {
      if (!state.services) state.services = [];
      state.services.push(action.payload);
    },

    onServicesUpdated: (state, action: PayloadAction<SelectOption[]>) => {
      if (!state.services) state.selectedServices = [];
      state.selectedServices = action.payload;
    },

    onServicesDeleted: (state, action: PayloadAction<number>) => {
      if (!state.services) return;
      const existingServices = [...state.services];
      const index = action.payload;
      delete existingServices[index];
      state.services = existingServices;
    },
  },
});

export const {
  onServicesAdded,
  onServicesLoaded,
  onServiceUpdated,
  onServicesDeleted,
  onServicesUpdated,
} = supplierServicesSlice.actions;

export default supplierServicesSlice.reducer;
